import { AxiosError } from "axios";
import { CreateToastType } from "providers/toast";
import { TFunction } from "react-i18next";

type ToastType = {
  error: CreateToastType;
  warning: CreateToastType;
};
export class Errors {
  error: CreateToastType;
  warning: CreateToastType;
  t: TFunction;

  constructor({ error, warning }: ToastType, translation: TFunction) {
    this.error = error;
    this.warning = warning;
    this.t = translation;
  }

  defaultError = (reason: AxiosError) => {
    switch (reason.response?.status) {
      case 401:
        this.warning({
          description: this.t("alerts.unauthorizedAction"),
        });
        break;
      case 500:
        if (reason.response?.data?.error && reason.response?.data?.error === 'Error: File data do not match the load layout') {
          this.error({
            description: this.t("alerts.fileDataMatch"),
          });
        } else {
          this.error({
            description: this.t("alerts.interruptedAction"),
          });
        }
        break;
      default:
        this.error({
          description: this.t("alerts.unforeseen"),
        });
        break;
    }
  };
}
